'use client'

import { MdOutlineThumbUp, MdOutlineThumbDown, MdOutlineTouchApp, MdSettings, MdThumbDown } from 'react-icons/md';
import { Button, Flex, Icon, List, ListIcon, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { PiHandsPrayingFill } from 'react-icons/pi';
import CognitionIcon from '../components/icons/cognitionIcon';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { BiBorderRadius } from 'react-icons/bi';

export default function FeaturesPage() {

  const theme = useTheme();
  const orange = theme.colors.text.medium.orange; 

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
      gap={"50px"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        gap={3}
        borderBottom={"1px solid"}
        borderColor="divider.grey"
        pb={3}
        width={"100%"}
        maxWidth={"900px"}
        >
        <Flex  borderRadius="50%" overflow={"hidden"} >
          <img
            width="100px"
            height="100px"
            src="/img/features/designer.png"
            />
        </Flex>
        <Text
          maxW={"350px"}
          textAlign={"center"}
          color="text.medium.purple"
          fontSize={"15px"}
        >
          Let’s take for example a Designer/User Researcher in a company working on two Web Apps : GeoVista and Terra Link.
        </Text>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={"900px"}
      >
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={"26px"} color={"text.medium.purple"}>Product Pages</Text>
        <Text mb={3} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">He creates on page by product. He can share it with other designers or product managers for collaborating.</Text>
        <Flex gap={2} flexDirection={"column"}>
          <img
            width={"100%"}
            src={"/img/features/feature_1.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
          <img
            width={"100%"}
            src={"/img/features/feature_2.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={"900px"}
      >
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={"26px"} color={"text.medium.blue"}>Persona Pages</Text>
        <Text mb={3} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">He creates two persona “Human Analyst” and “Chief Human Analyst” based on his knowedge on these products. He can share it with other designers or product managers for collaborating.</Text>
        <Flex gap={2} flexDirection={"column"}>
          <img
            width={"100%"}
            src={"/img/features/feature_3.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
          <img
            width={"100%"}
            src={"/img/features/feature_4.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={"900px"}
      >
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={"26px"} color={"text.medium.orange"}>Experiences & Insights</Text>
        <Text mb={3} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">He adds some “experiences” in the products then links them to a persona. For instance he adds the experience “Create a dashboard” in the product "GeoVista" and link it to the persona “Human Analyst”.</Text>
        <Flex gap={2} flexDirection={"column"}>
          <img
            width={"100%"}
            src={"/img/features/feature_5.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
          <Text my={2} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">The experiences will automatically appear in the persona page.</Text>
          <img
            width={"100%"}
            src={"/img/features/feature_6.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
          <Text my={2} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">Then he adds insights as he collects them during his studies.</Text>
          <img
            width={"100%"}
            src={"/img/features/feature_7.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
          <Text my={2} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">He can set the insights as outdated for instance when his team solved a user's frustration by implementing a new feature.</Text>
          <img
            width={"100%"}
            src={"/img/features/feature_8.jpg"}
            style={
              {
                boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)',
                borderRadius: '3%',
              }
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={"900px"}
      >
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={"26px"} color={"text.medium.dark"}>Home</Text>
        <Text mb={3} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">All the information will appear on his home in addition to reminder for negative and improvement feedback to keep in track the insight.</Text>
        <Flex gap={2} flexDirection={"column"}>
          <img width={"100%"} src={"/img/features/feature_9.jpg"}/>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={"900px"}
      >
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={"26px"} color={"text.medium.dark"}>Upcoming Features</Text>
        <Text mb={3} maxW={"524px"} fontSize={"14px"} color="text.dark.grey">Open this <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdJqjD9JO2tFDA0iUa6rMVARb7Q7nrb37fwh6ntaliQ_1dogQ/viewform" target='_blank' style={{
          color: orange
        }}>form</Link> to see the upcoming features (may be subject to change as roadmap evolves). Fill the form to rank the features based on the importancy.</Text>
        <Flex gap={2} flexDirection={"column"} alignSelf={"stretch"}>
          <iframe
            style={{maxWidth: '900px', width:"100%"}}
            height={window.innerHeight + 'px'}
            src="https://docs.google.com/forms/d/e/1FAIpQLSdJqjD9JO2tFDA0iUa6rMVARb7Q7nrb37fwh6ntaliQ_1dogQ/viewform" /> 
        </Flex>
      </Flex>
    </Flex>
  );
}
