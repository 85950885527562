'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { MdMenu } from 'react-icons/md';

export default function HeaderMenuMobile() {

  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  useEffect(() => {
    onClose();
  }, [location.pathname])

  return (
    <Flex
      flexDirection={"row"}
      alignItems={"center"}
    >
      <IconButton
        variant="greyTransparent"
        color="text.medium.grey"
        fontSize='16px'
        icon={<MdMenu />}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody
            px={0}
            mt={"40px"}
            w="100%"
             >
            <Link to="/">
              <Text
                fontSize={"16px"}
                height={"50px"}
                color={(
                  location.pathname.includes('home') ? 'fill.orange' : 'text.medium.dark'
                )}
                bg={(
                  location.pathname.includes('home') ? 'background.orange' : 'white'
                )}
                px={3}
                lineHeight={"50px"}
              >Home</Text>
            </Link>
            <Link to="/features">
              <Text
                fontSize={"16px"}
                height={"50px"}
                color={(
                  location.pathname.includes('features') ? 'fill.orange' : 'text.medium.dark'
                )}
                bg={(
                  location.pathname.includes('features') ? 'background.orange' : 'white'
                )}
                px={3}
                lineHeight={"50px"}
              >Features</Text>
            </Link>
            <Link to="/pricing">
              <Text
                fontSize={"16px"}
                height={"50px"}
                color={(
                  location.pathname.includes('pricing') ? 'fill.orange' : 'text.medium.dark'
                )}
                bg={(
                  location.pathname.includes('pricing') ? 'background.orange' : 'white'
                )}
                px={3}
                lineHeight={"50px"}
              >Pricing</Text>
            </Link>
            <Link to="https://www.support.bibliona.com/" target='_blank'>
              <Text
                fontSize={"16px"}
                height={"50px"}
                color={(
                  location.pathname.includes('support') ? 'fill.orange' : 'text.medium.dark'
                )}
                bg={(
                  location.pathname.includes('pricing') ? 'background.orange' : 'white'
                )}
                px={3}
                lineHeight={"50px"}
                >Support</Text>
            </Link>
            <Link to="http://app.bibliona.com" 
              display="flex"
              >
              <Button
                variant="orangeFill"
                mx={"15px"}
                w="calc(100% - 30px)"
                >Try it for Free</Button>
            </Link>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
