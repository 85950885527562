import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import AppRoutes from './Routes';
import theme from './lib/theme/chakra.theme';

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <AppRoutes />
      </ChakraProvider>
    </>
  );
}

export default App;
