'use client'

import { MdOutlineThumbUp, MdOutlineThumbDown, MdOutlineTouchApp, MdSettings, MdThumbDown, MdFormatQuote } from 'react-icons/md';
import { Button, Flex, Icon, Image, List, ListIcon, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { PiHandsPrayingFill } from 'react-icons/pi';
import CognitionIcon from '../components/icons/cognitionIcon';
import { Link } from 'react-router-dom';

export default function HomePage() {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
    >
      <Flex
        maxWidth={"900px"}
        width={"100%"}
        backgroundColor={"#FFF7F4"}
        p={["30px", "50px"]}
        justifyContent={"space-between"}
        borderRadius={"20px"}
        marginBottom={"25px"}
        flexDirection={['column', 'row']}
        gap={["30px", "40px"]}
        alignItems={"center"}
      >
        <Flex
          flexDirection={"column"}
        >
          <Text
            fontSize={"40px"}
            color="text.medium.orange"
            lineHeight={"50px"}
            fontFamily={'var(--font-vidaloka)'}
            marginBottom={2}
            maxW={"370px"}
          >Better organize and share your insights</Text>
          <Text
            fontSize={"20px"}
            color="#C35925"
            maxW={"390px"}
            lineHeight={"30px"}
            maxWidth={"300px"}
          >By making your own Persona and Product Library.</Text>
          <Link to="https://www.app.bibliona.com" target='_blank'>
              <Button width={"143px"} mt={3} variant="orangeFill" size={"md"} mb={2}>Try it !</Button>
          </Link>
        </Flex>
        <Flex
          gap={["15px", "10px"]}
          flexWrap={"wrap"}
          alignItems={"center"}
          maxW={"345px"}
        >
          <Image src="/img/home/home_1_1.jpg" width={["70px", "108px"]} flexShrink={"0"} />
          <Image src="/img/home/home_1_2.jpg" width={["70px", "108px"]} flexShrink={"0"} />
          <Image src="/img/home/home_1_3.jpg" width={["70px", "108px"]} flexShrink={"0"} />
          <Image src="/img/home/home_1_4.jpg" width={["70px", "108px"]} flexShrink={"0"} />
          <Image src="/img/home/home_1_5.jpg" width={["70px", "108px"]} flexShrink={"0"} />
          <Image src="/img/home/home_1_6.jpg" width={["70px", "108px"]} flexShrink={"0"} />
        </Flex>
      </Flex>
      <Flex
        maxWidth={"900px"}
        width={"100%"}
        backgroundColor={"background.blue"}
        p={["30px", "50px"]}
        borderRadius={"20px"}
        marginBottom={"25px"}
        flexDirection={['column-reverse', 'row']}
        gap={["30px", "50px"]}
        alignItems={"center"}
      >
        <Flex
          maxWidth={"358px"}
          flexWrap={"wrap"}
          gap={"15px"}
          justifyContent={["center", "flex-start"]}
        >
          <Image flexShrink={"0"} src="/img/home/home_4.png" width={["110px", "115px"]} height={["110px", "115px"]} />
          <Image flexShrink={"0"} src="/img/home/home_5.png" width={["110px", "115px"]} height={["110px", "115px"]} />
          <Image flexShrink={"0"} src="/img/home/home_6.png" width={["110px", "115px"]} height={["110px", "115px"]} />
          <Image flexShrink={"0"} src="/img/home/home_7.png" width={["110px", "115px"]} height={["110px", "115px"]} />
        </Flex>
        <Flex
          flexDirection={"column"}
          maxWidth={"569px"}
          gap={"20px"}
        >
          <Text
            fontSize={"40px"}
            lineHeight={"50px"}
            color="#4d9bd4"
            fontFamily={'var(--font-vidaloka)'}
          >Do you work on many personas and products?</Text>
          <UnorderedList
            color="text.medium.blue"
            fontSize={"16px"}
            lineHeight={"24px"}
            maxW={"350px"}
          >
            <ListItem>Better keep track of insights so you won’t forget to address any user’s frustration anymore.</ListItem>
            <ListItem>Share more easily your work with your teams.</ListItem>
            <ListItem>Centralize your work so you don't spend time going through many old studies anymore.</ListItem>
            <ListItem>Onboard quickly and easily new designers.</ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
      <Flex
        maxWidth={"900px"}
        width={"100%"}
        backgroundColor={"#EAEFFF"}
        p={["30px", "50px"]}
        borderRadius={"20px"}
        flexDirection={"column"}
        gap={"50px"}
        marginBottom={"25px"}
      >
        <Flex
          flexDirection={"column"}
          gap={"20px"}
        >
          <Text
            fontSize={"40px"}
            lineHeight={"50px"}
            color="text.medium.purple"
            fontFamily={'var(--font-vidaloka)'}
            maxW={"561px"}
          >Make your own persona and product library !</Text>
          <Image src="/img/home/home_3_1.jpg" borderRadius={"20px"} boxShadow={"0px 19px 25px -5px rgba(0,0,0,0.13)"} />
        </Flex>
        <Flex flexDirection={"column"} gap={"20px"}>
          <Text
            fontSize={"25px"}
            color="text.medium.purple"
            maxW={"561px"}
          >List all your insights</Text>
          <Image src="/img/home/home_3_2.jpg" borderRadius={"20px"} boxShadow={"0px 19px 25px -5px rgba(0,0,0,0.13)"} />
        </Flex>
        <Flex flexDirection={"column"} gap={"20px"}>
          <Text
            fontSize={"25px"}
            color="text.medium.purple"
            maxW={"561px"}
          >Archive outdated insights</Text>
          <Image src="/img/home/home_3_3.jpg" borderRadius={"20px"} boxShadow={"0px 19px 25px -5px rgba(0,0,0,0.13)"} />
        </Flex>
        <Flex flexDirection={"column"} gap={"20px"}>
          <Text
            fontSize={"25px"}
            color="text.medium.purple"
            maxW={"561px"}
          >Share and contribute with colleagues !</Text>
          <Image src="/img/home/home_3_4.jpg" borderRadius={"20px"} boxShadow={"0px 19px 25px -5px rgba(0,0,0,0.13)"} />
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Text
          fontSize={"40px"}
          lineHeight={"50px"}
          color="fill.purple"
          fontFamily={'var(--font-vidaloka)'}
        >Try it !</Text>
        <Text
          fontSize={"40px"}
          lineHeight={"50px"}
          fontFamily={'var(--font-vidaloka)'}
          color="fill.purple"
          mb={3}
        >The beta is free !</Text>
        <Link to="https://www.app.bibliona.com" target='_blank'>
          <Button width={"143px"} variant="purpleFill" size={"md"} mb={2}>Start for Free</Button>
        </Link>
        <Link to="/features">
          <Button width={"143px"} variant="purpleOutline" size={"md"} mb={2}>See features</Button>
        </Link>
        <Link to="/pricing">
          <Button width={"143px"} size={"md"} variant="purpleTransparent" color="fill.purple">see pricing</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
