export const divider = {
  baseStyle: {
  },
  variants: {
    blue: {
      borderColor: 'divider.blue',
      borderWidth: '1px',
      borderStyle: 'solid'
    },
    grey: {
      borderColor: 'divider.grey',
      borderWidth: '1px',
      borderStyle: 'solid'
    },
    purple: {
      borderColor: 'divider.purple',
      borderWidth: '1px',
      borderStyle: 'solid'
    },
    orange: {
      borderColor: 'divider.orange',
      borderWidth: '1px',
      borderStyle: 'solid'
    },
  },
  defaultProps: {
    variant: 'grey',
  }
};