import {extendTheme } from '@chakra-ui/react'
import {button} from './button.theme';
import {input} from './input.theme';
import {textarea} from './textarea.theme';
import {heading} from './heading.theme';
import {divider} from './divider.theme';
import {globalStyle} from './globalStyle.theme';
import {modal} from './modal.theme';
import {text} from './text.theme';
import {colors} from './colors.theme';
import {fontSizes} from './fontSizes.theme';
import {spaces} from './space.theme';
import {tag} from './tag.theme';
import {tabs } from './tabs.theme';
import { draggableBox } from './draggableBox.theme';
import { numberInput } from './numberInput';
import { popover } from './popover.theme';

const theme = extendTheme({
  colors: colors,
  textStyles: text,
  fontSizes: fontSizes,
  space: spaces,
  styles: {
    global : globalStyle
  },
  components: {
    Button: button,
    Input: input,
    Textarea: textarea,
    Heading: heading,
    Divider: divider,
    Modal: modal,
    Tag: tag,
    Tabs: tabs,
    DraggableBox: draggableBox,
    NumberInput: numberInput,
    Popover: popover
  }
});

export default theme