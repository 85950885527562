export const tag = {
  baseStyle: {
    container: {
      bg: 'background.grey',
      color: 'text.medium.grey',
      fontSize: 12,
      borderRadius: '50px',
      px: '10px',
      py: '4px'
    }
  },
  variants: {
    transparent: {
      container: {
        bg: 'transparent',
        px: '0',
        py: '4px',
        borderColor: 'transparent'
      }
    },
    grey: {
      container: {
        bg: 'text.disable.grey',
        color: 'fill.grey',
        borderColor: 'divider.grey'
      }
    },
    greyOutline: {
      bg: 'background.grey',
      color: 'fill.grey',
      borderColor: 'divider.grey'
    },
    blue: {
      container: {
        bg: 'background.blue',
        color: 'fill.blue',
      }
    },
    orange: {
      container: {
        bg: 'background.orange',
        color: 'fill.orange',
      }
    },
    purple: {
      container: {
        bg: 'background.purple',
        color: 'fill.purple',
      }
    },
    red: {
      container: {
        bg: 'background.red',
        color: 'fill.red',
      }
    },
    yellow: {
      container: {
        bg: 'background.yellow',
        color: 'fill.yellow',
      }
    },
    green: {
      container: {
        bg: 'background.green',
        color: 'fill.green',
      }
    },
  },
  defaultProps: {
    variant: 'grey',
  }
};