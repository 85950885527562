import { Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {

  const navigate = useNavigate();
  
  return (
    <>
      <Flex
          py={3}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          overflowY={"auto"}
          flexDirection={"column"}
          gap={3}
        >
          <Text color={"text.medium.orange"} fontSize={"16px"}>404 - Page Not Found</Text>
          <Text
            maxWidth={"300px"}
            textAlign={"justify"}
            >The page you’re looking for no longer exists or has been moved.
Try going back or explore our other sections to find what you need.</Text>
          <Button variant="orangeOutline" onClick={() => {
            navigate('/home')
          }}>Go Back to Home Page</Button>
      </Flex>
    </>
  )
}
