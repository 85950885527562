import { Button, Flex, Icon, List, ListIcon, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react'

export default function PricingPage() {

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
      gap={"50px"}
    >
      <Text fontFamily={'var(--font-vidaloka)'} fontSize={"40px"} color={"text.dark.grey"}>Pricing</Text>
      <Flex
        maxW={"900px"}
        flexDirection={"column"}
        width={"100%"}
        >
        <Flex
          gap={3}
          flexWrap={"wrap"}
          flexDirection={["column", "row"]}
          >
          <Flex
            flexDirection={"column"}
            border={"1px solid"}
            borderColor={"divider.orange"}
            borderRadius={"20px"}
            backgroundColor={"background.orange"}
            padding={"20px"}
            flex={"1"}
            >
            <Text color="text.medium.orange">Basic</Text>
            <Text
            color="text.medium.orange"
            fontSize={"30px"}
            lineHeight={"30px"}
            fontFamily={'var(--font-vidaloka)'}>Free</Text>
            <Link
              to={"https://app.bibliona.com"}
              target="_blank"
              >
              <Button my={"30px"} variant="orangeFill" width={"100%"} >Try It !</Button>
            </Link>
            <Flex flexDirection={"column"}>
              <Text flexDirection={"column"} color={"text.medium.orange"}>Includes: </Text>
              <UnorderedList color={"text.medium.orange"}>
                <ListItem>Unlimited products</ListItem>
                <ListItem>Unlimited persona</ListItem>
                <ListItem>Unlimited Experiences</ListItem>
              </UnorderedList>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"column"}
            border={"1px solid"}
            borderColor={"divider.blue"}
            borderRadius={"20px"}
            backgroundColor={"background.blue"}
            padding={"20px"}
            flex={"1"}
            >
            <Text color="text.medium.blue">Pro</Text>
            <Flex alignItems={"baseline"}>
              <Text
                color="text.medium.blue"
                fontSize={"30px"}
                fontFamily={'var(--font-vidaloka)'}
                lineHeight={"30px"}
                textDecoration={"line-through"}
              >9.99 $US</Text>
              <Text
                color="text.medium.blue"
                fontSize={"20px"}
                fontFamily={'var(--font-vidaloka)'}
                lineHeight={"30px"}
              >/month</Text>
            </Flex>
            <Flex color="text.medium.blue" height={"0"}>Free During the Beta !</Flex>
            <Link
              to={"https://app.bibliona.com"}
              target="_blank"
              >
              <Button my={"30px"} variant="blueFill" width={"100%"} >Select</Button>
            </Link>
            <Flex flexDirection={"column"}>
              <Text flexDirection={"column"} color={"text.medium.blue"} >Includes: </Text>
              <UnorderedList color={"text.medium.blue"}>
              <ListItem>Sharing and collaboration features</ListItem>
              <ListItem>Unlimited Products</ListItem>
              <ListItem>Unlimited Persona</ListItem>
              <ListItem>Unlimited Experiences</ListItem>
            </UnorderedList>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"column"}
            border={"1px solid"}
            borderColor={"divider.grey"}
            borderRadius={"20px"}
            backgroundColor={"background.grey"}
            padding={"20px"}
            flex={1}
            >
            <Text color="text.light.grey">Enterprise</Text>
            <Text
              color="text.light.grey"
              fontSize={"30px"}
              fontFamily={'var(--font-vidaloka)'}
              lineHeight={"30px"}
            >Coming Soon</Text>
            <Button variant="blueFill" my={"30px"} opacity={0} >Select</Button>
            <Flex flexDirection={"column"}>
              <Text flexDirection={"column"} color={"text.medium.grey"} >Includes: </Text>
              <UnorderedList color={"text.medium.grey"}>
              <ListItem>Library of Personas and Products accessible to any collaborators</ListItem>
              <ListItem>Common Billing</ListItem>
              <ListItem>Unlimited Products</ListItem>
              <ListItem>Unlimited Persona</ListItem>
              <ListItem>Unlimited Experiences</ListItem>
              <ListItem>Sharing and collaboration features</ListItem>
            </UnorderedList>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Link  to="/features">
          <Button width={"143px"}variant="greyFill" size={"md"} mb={2}>See features</Button>
        </Link>
        <Link to="https://www.support.bibliona.com/" target='_blank'>
          <Button width={"143px"}variant="greyOutline" size={"md"} mb={2}>Contact Us</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
