export const tabs = {
  sizes: {
    md: {
      tab: {
        fontSize: '12px',
        py: '0px',
        px: '16px',
      },
      tabpanel: {
        py: '0px',
        px: '0px',
      },
      tablist: {
        px: '0px',
        py: '0px'
      }
    }
  },
  variants: {
    orangeFlat: {
      tab: {
        border: 'none',
        borderColor: 'transparent',
        bg: 'transparent',
        h: '32px',
        px: '16px',
        _selected: {
          bg: 'transparent',
          color: 'fill.orange',
          borderColor: 'fill.orange',
          borderBottom: '1px solid',
        },
      },
      tabpanel : {
        px: '0px',
        py: '0px'
      },
      tablist: {
        px: '3',
        py: '0px',
        pt: '2'
      }
    }
  },
  defaultProps: {
    
  }
};